import { onReady } from '../common';
import { add, format, parse } from 'date-fns';
// let { Swal } = require('sweetalert2');
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

let USDatetimeFormat = "MM/dd/yyyy hh:mm aa";

let buildCalendar = () => {
  let calendarEl = $('#calendar');
  if (calendarEl.length < 1) { return };

  let calendarId = calendarEl.data('calendar-id');
  let businessHours = calendarEl.data('business-hours');
  let appointmentsUrl = calendarEl.data('appointments-url');

  let calendar;

  calendar = new FullCalendar.Calendar(calendarEl.get(0), {
    plugins: [ 'timeGrid', 'dayGrid', 'interaction', 'list' ],
    defaultView: 'timeGridDay',
    header: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridDay,listWeek',
    },
    timeZone: "America/New_York",
    nowIndicator: true,
    selectable: false,
    editable: false,
    height: 'parent',
    // contentHeight: 'parent',
    businessHours,
    navLinks: true, // can click day/week names to navigate views
    eventLimit: true, // allow "more" link when too many events

    // see https://fullcalendar.io/docs/events-json-feed
    events: {
      url: appointmentsUrl,
      timeZoneParam: 'tz',
      extraParams: {
        calendar_id: calendarId
      }
    }
  });

  calendar.render();
};

onReady(() => {
  buildCalendar();
});


export let initializeCalendar = ({ form }) => {
  $(form).find('.datetime-calendar-btn').off('click.dan');
  $(form).find('.datetime-calendar-btn').on('click.dan', ev => {
    let externalCalendarUrl = $(ev.target).data('external-calendar-url');
    let calendarId = $(form).find('.calendar-select').val();

    if (calendarId) {
      window.open(`${externalCalendarUrl}?calendar_id=${calendarId}`, "dan-calendar",
        "left=300,top=0,width=720,height=1080,menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes");
    }
  });

};
