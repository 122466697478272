
export class ImportTask {
  COMPLETE = 'COMPLETE';
  PROGRESS = 'PROGRESS';

  constructor({ consumer, importId, onComplete = null, onProgress = null }) {
    this.errors = [];
    this.total = 0;
    this.success = 0;

    let task = this;

    this.subscription = consumer.subscriptions.create({ channel: "ImportPreviewChannel", id: importId }, {
      connected() {
        // Called when the subscription is ready for use on the server
        console.debug('connected!');
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
        console.debug('DISconnected!');
      },

      received({ type, percentage, error, total, errors }) {
        // Called when there's incoming data on the websocket for this channel

        switch (type) {
          case task.COMPLETE: {
            if (onProgress) {
              onProgress.call(null, { percentage: 100, error: null });
            }

            if (onComplete) {
              onComplete.call(null, { total, errors, success: (total - errors) })
            }
            // Unsubscribe
            consumer.subscriptions.remove(task.subscription);
            break;
          }
          case task.PROGRESS: {
            if (onProgress) {
              onProgress.call(null, { percentage, error });
            }

            if (error) {
              task.errors.push(error)
            } else {
              task.success += 1;
            }
            task.total += 1;
            break
          }
          default: {
            console.error(`Unknown message type ${type}`)
            break
          }
        }
      }
    });

  }
}
