let APPOINTMENT_SET = 'APPOINTMENT_SET';
let AppointmentSet = 'Appointment set';
let CALL_BACK = 'CALL_BACK';
let Call_back = 'Call back';
let Callback = 'Call back';
let NoteAdded = 'Note Added';
let ContactAdded = 'Contact Added';
let AppointmentCancelled = 'CANCELLED';
let CallbackCancelled = 'Call back cancelled';
let CALL_BACK_CANCELLED = 'CALL_BACK_CANCELLED';
let Sold = 'Sold';
let SendEmail = 'Send Email';

module.exports = {
    APPOINTMENT_SET,
    CALL_BACK,
    AppointmentSet,
    Call_back,
    Callback,
    NoteAdded,
    ContactAdded,
    AppointmentCancelled,
    CallbackCancelled,
    CALL_BACK_CANCELLED,
    Sold,
    SendEmail,
}