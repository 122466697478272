import { onReady } from '../common';
import { renderResult } from '../result';
import { isoToDateTimeNY } from '../time';

let importBusinessesTable = () => {
  let tbl = $("table.imported-businesses").DataTable({
    retrieve: true,
    ajax: {
      url: $("table.imported-businesses").data('url')
    },
    columns: [
      // %th
      //   Date
      //   .text-muted Time
      {
        data: "created_at", render: (data, type, row) => {
          if (type == 'sort') return row.created_at;

          return isoToDateTimeNY(row.created_at);
        }
      },
      // %th Set by
      {
        data: "creator_name", render: (data, type, row) => {
          return row.creator_name;
        }
      },
      // %th
      //   Business
      //   .text-muted Phone
      {
        data: "title", render: function (data, type, row) {
          return `<a target='dan-app' href='${row.business_url}'>${row.title}</a>
          <div class='text-muted' style='white-space: normal'>${row.phone}</div>`;
        }
      },
      // %th
      //   Address
      //   .text-muted City, State
      {
        data: "address1", render: function (data, type, row) {
          return `${row.address1}
          <div class='text-muted'>${row.city}, ${row.state} (${row.zip})</div>`;
        }
      },

      // %th
      //   Contact
      {
        data: "contact_name", render: function (data, type, row) {
          if (!row.contact_name) return '';

          let tooltipText = `Name: ${row.contact_name || ''}<br>
          Email: ${row.contact_email || ''}<br>
          Title: ${row.contact_title || ''}<br>
          Phone: ${row.contact_phone || ''}`;

          let icon = $("<i />").attr('data-feather', 'user').addClass('text-info');

          let div = $('<div/>').attr('data-toggle', 'tooltip')
            .attr('title', tooltipText)
            .addClass('d-inline-block')
            .append(icon);

          return div.prop('outerHTML');
        }
      },

      // %th
      //   Lead Source
      //   .text-muted Label
      {
        data: "lead_source", render: (data, type, row) => {
          return `${row.lead_source || ''}
          <div class='text-muted'>${row.label || ''}</div>`;
        }
      },

      // %th
      //   Last Activity
      //   .text-muted Date / Time
      {
        data: "last_activity", render: (data, type, row) => {
          if (!row.last_activity) return '';

          if (type == 'sort') {
            return row.last_activity.created_at;
          };

          let icon = renderResult(row.last_activity);

          let datetime = isoToDateTimeNY(row.last_activity.created_at);

          return `${icon}
          <div class='text-muted'>${datetime}</div>`;
        }
      },
      {
        data: "last_activity", orderable: false,
        render: (data, type, row) => {
          return `<span class="dropdown">
                     <a href="#" class="btn btn-sm btn-clean btn-icon btn-icon-md" data-toggle="dropdown"
                     aria-expanded="false">
                    <i class="la la-ellipsis-h"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="/businesses/${row.id}/edit">
                          <i class="la la-edit"></i> Edit Business</a>
                    </div></span>`;
        }
      }
    ]
  });

};

onReady(() => {
  $(document).on("ajax:error", "form.new_importers_csv", (ev) => {
    let res = ev.detail[2];
    $("#new-import-preview").html(res.responseText);
  });

  importBusinessesTable();
});
