import { isoToTableCellNY, isoToDateTimeNY } from 'time';
import {
    CALL_BACK_CANCELLED,
    Sold,
    AppointmentCancelled,
    AppointmentSet,
    CallbackCancelled,
    Callback,
    ContactAdded,
    SendEmail
} from 'call_results';

let activityIcon = ({ icon, iconClass, title }) => {
    let i = $('<i>').addClass(iconClass).attr({ 'data-feather': icon });
    return $('<div>').addClass('d-inline-block ml-3')
        .attr({
            'data-toggle': 'tooltip',
            title: title
        }).html(i);
};

let renderResult = (row) => {
    if (row.result == Callback) {
        let callback_at = isoToDateTimeNY(row.callback_at);

        let i = activityIcon({
            icon: 'clock',
            iconClass: 'text-info',
            title: callback_at
        });

        return `Call back
    ${i.prop('outerHTML')}`;
    }

    if (row.result == CallbackCancelled) {
        let callback_at = isoToDateTimeNY(row.callback_at);

        let i = activityIcon({
            icon: 'phone-off',
            iconClass: 'text-info',
            title: callback_at
        });

        return `Call back cancelled
    ${i.prop('outerHTML')}`;
    }

    if (row.result == ContactAdded) {
        let i = activityIcon({
            icon: 'user',
            iconClass: 'text-info',
            title: row.contact_name
        });

        return `Contact Added
    ${i.prop('outerHTML')}`;
    }

    if (row.result == AppointmentSet) {
        let appointment_datetime = isoToDateTimeNY(row.appointment_datetime);

        let i = activityIcon({
            icon: 'map-pin',
            iconClass: 'text-info',
            title: `Name: ${row.appointment_contact_name}<br>
      Title: ${row.appointment_contact_title}<br>
      Time: ${appointment_datetime}<br>
      Address: ${row.appointment_address}`
        });

        let text = AppointmentSet;
        if (row.appointment_status == AppointmentCancelled) {
            text = "Appointment Cancelled";
        };
        return `${text}
    ${i.prop('outerHTML')}`;
    }

    if (row.result == Sold) {
        let i = activityIcon({
            icon: 'user',
            iconClass: 'text-info',
            title: `Contact: ${row.sell_contact_name}<br>
      Product / Service: ${row.sell_product}`
        });

        let text = 'Sold';

        return `${text}
    ${i.prop('outerHTML')}`;
    }

    if (row.result == SendEmail) {
        let i = activityIcon({
            icon: 'mail',
            iconClass: 'text-info',
            title: `Total ${row.send_email_total || 1} today`
        });

        return `Send Email
    ${i.prop('outerHTML')}`;
    }

    return `${row.result_humanized}`;
}

export {
    renderResult
}