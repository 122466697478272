import { onReady } from '../common';
const $ = require('jquery');
require('select2');

const stars = (rating) => {
  if (!rating) {
    return '';
  }

  rating = Number.parseFloat(rating);

  const displayRating = rating.toFixed(1);
  let s = '';
  for (let i = 0; i < Math.floor(rating); i++) {
    s += "<i class='fa fa-star'></i>";
  }

  let h = '';
  if ((rating % 1) !== 0) {
    h = "<i class='fa fa-star-half-alt'></i>";
  }

  return `
  <div class="rating text-warning">
    <span class='kt-font-bolder'>${displayRating}</span>
    ${s}${h}
  </div>
  `;

  // .rating.text-warning
  //   %span.kt-font-bolder= business.rating.to_f.round(1)
  //   - business.rating.floor.times do
  //     %i.fa.fa-star
  //   - unless (business.rating % 1).zero?
  //   %i.fa.fa-star-half-alt
};

const searchBusinessesTable = () => {
  $('.search-businesses-table').DataTable({
    retrieve: true,
    pageLength: 20,
    lengthMenu: [10, 20, 50, 100],
    deferRender: true,
    processing: true,
    serverSide: true,
    order: [[0, 'desc']],
    columns: [
      {
        data: 'created_at',
        visible: false
      },
      {
        data: 'logo',
        orderable: false,
        render: (data, type, row) => {
          if (row.logo_url) {
            return `<div class="img-wrapper">
            <img alt="${row.title} Logo" class="img-fluid rounded" src="${row.logo_url}">
            </div>`;
          } else {
            return '';
          }
        }
      },
      {
        class: 'word-wrap',
        data: 'title',
        render: (data, type, row) => {
          let existing = '';
          if (row.gbh_business) {
            existing = `<a class='existing-customer text-danger ml-2'
              data-toggle='tooltip' title="Existing Customer: <br> <div class='no-wrap'>${row.gbh_business.title}</div>"
              href='${row.gbh_business.gbh_url}' target='gbh'>
              <i data-feather='alert-octagon'> </i>
            </a>`;
          }

          let imported = '';
          if (row.import_type === 'MANUAL') {
            imported = `<div class='text-danger ml-2'
              data-toggle='tooltip' title="Manual Import: ${row.lead_source}">
              <i data-feather='alert-triangle'> </i>
            </div>`;
          }

          return `<div class='d-flex align-items-center mb-2'>
            <a href='/businesses/${row.id}'>${row.title}</a>
            ${existing} ${imported}
          </div>
          <div class='text-muted word-wrap'>${row.categories_text}</div>`;
        }
      },
      {
        orderable: false,
        class: 'nowrap',
        render: (data, type, row) => {
          let yrc = '&nbsp;';
          if (row.yelp_reviews_count) {
            yrc = `<div class="ml-2">(${row.yelp_reviews_count || ''})</div>`;
          }
          let grc = '&nbsp;';
          if (row.google_reviews_count) {
            grc = `<div class="ml-2">(${row.google_reviews_count || ''})</div>`;
          }

          return `<div class='d-flex align-items-center mb-2 nowrap'>
            ${stars(row.rating) || ''}
            ${yrc}
          </div>
          <div class='d-flex text-muted nowrap'>
            ${stars(row.google_rating) || ''}
            ${grc}
          </div>`;
        }
      },
      {
        orderable: false,
        render: (data, type, row) => {
          let yurl = '&nbsp;';
          if (row.url) {
            yurl = `<a href='${row.url}' target='business'><i class='la la-yelp'></i></a>`;
          }

          let gurl = '&nbsp;';
          let gurlLocation = row.google_url;
          if (!gurlLocation) {
            gurlLocation = row.google_search_url;
          }

          if (gurlLocation) {
            gurl = `<a href='${gurlLocation}' target='business'><i class='la la-google'></i></a>`;
          }

          return `<div class='d-flex align-items-center mb-2 nowrap'>
            ${yurl}
          </div>
          <div class='d-flex text-muted nowrap'>
            ${gurl}
          </div>`;
        }
      },
      {
        data: 'address1',
        render: (data, type, row) => {
          return `${row.address1 || ''} ${row.address2 || ''}
          <div class='text-muted'>${row.city}, ${row.state}</div>`;
        }
      },
      {
        data: 'phone',
        render: (data, type, row) => {
          return `${row.format_phone}`;
        }
      }
    ]
  });
};

onReady(() => {
  $('.more-cities').on('click', (ev) => {
    const lg = $(ev.target).closest('.list-group');
    lg.find('.more-cities').remove();
    lg.find('.list-group-item.d-none').removeClass('d-none').addClass('d-flex');
  });

  // Search autocomplete
  $('.dashboard-search-term').select2({
    tags: true,
    allowClear: true,
    placeholder: '',
    ajax: {
      url: '/search/autocomplete',
      dataType: 'json'
    }
  });

  $('.dashboard-search-city-state').select2({
    allowClear: true,
    placeholder: 'All',
    ajax: {
      url: '/search/autocomplete_city_state',
      dataType: 'json'
    }
  });

  searchBusinessesTable();
});
