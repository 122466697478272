
import { Controller } from "stimulus"
import Swal from "sweetalert2";
let $ = jQuery;

export default class extends Controller {
  static targets = ["businessName", "businessLocation", "results", "searchSubmit",
    "yelpId", "googleId"]

  connect() {
    this.generateSearchToken()
    this.complete = false
    this.searchSubmitTarget.classList.toggle('disabled', true)

    // this.businessName = "Chelsea Market"
    // this.businessLocation = "New York"

    this.businessLocation = $(this.businessLocationTarget).val()
    this.businessName = $(this.businessNameTarget).val()

    if (this.businessLocation !== '' || this.businessName !== '') {
      this.enableDisableSubmit()
    }

    let t = this;

    $(".clear-google-rating").on('click', () => {
      t.clearGoogleRating();
    })

    $(".clear-yelp-rating").on('click', () => {
      t.clearYelpRating();
    })
  }

  clearGoogleRating() {
    $('[name="business[google_rating]"]').prop('checked', false)
    $("#business_google_rating").val('');
  }

  clearYelpRating() {
    $('[name="business[rating]"]').prop('checked', false)
    $("#business_rating").val('');
  }

  generateSearchToken() {
    this.searchToken = Math.random().toString(36).substr(2, 5);
  }

  setName(ev) {
    this.businessName = $(ev.target).val()
    this.enableDisableSubmit()
  }

  setLocation(ev) {
    this.businessLocation = $(ev.target).val()
    this.enableDisableSubmit()
  }

  enableDisableSubmit() {
    if (!this.businessLocation || this.businessLocation.length < 2) {
      this.businessLocationTarget.classList.toggle('is-invalid', true)
    } else {
      this.businessLocationTarget.classList.toggle('is-invalid', false)
    }

    if (!this.businessName || this.businessName.length < 2) {
      this.businessNameTarget.classList.toggle('is-invalid', true)
    } else {
      this.businessNameTarget.classList.toggle('is-invalid', false)
    }

    if (!this.businessLocation || !this.businessName ||
      this.businessLocation.length < 2 || this.businessName.length < 2) {
      this.searchSubmitTarget.classList.toggle('disabled', true)
      this.complete = false
    } else {
      this.searchSubmitTarget.classList.toggle('disabled', false)
      this.complete = true
    }
  }

  search(ev) {
    ev.preventDefault()
    ev.stopPropagation()

    if (!this.complete) {
      return
    }

    $.ajax({
      url: '/businesses/new_autocomplete',
      data: { query: this.businessName, location: this.businessLocation, token: this.searchToken,
        yelp_id: $(this.yelpIdTarget).val(), google_id: $(this.googleIdTarget).val(),
         },
      success: (resp) => {
        $(this.resultsTarget).empty().html(resp);

        $('[data-toggle="tooltip"]').tooltip({
          container: 'body',
          html: true
        })
      },
      error: () => {
        $(this.resultsTarget).empty();
        Swal.fire({
          icon: 'error',
          title: "Something went wrong",
          text: "Loading Yelp and Google results failed"
        })
      }
    })
  }

  fill(ev) {
    let yelp = ev.target.dataset["value"].indexOf('yelp') > -1

    // Find all fill links
    let current = $(ev.target)
    let lg = $(ev.target).closest('.list-group');
    if (yelp) {
      // clear other links and add check mark to current item
      lg.find(".list-group-item-action.yelp").removeClass('active');
    } else {
      lg.find(".list-group-item-action.google").removeClass('active');
    }
    current.addClass('active');

    $.ajax({
      url: '/businesses/autocomplete_fill',
      data: ev.target.dataset,
      success: (resp) => {

        if (yelp) {
          this.fillYelp(resp)
        } else {
          this.fillGoogle(resp)

          // sessiontoken — A random string which identifies an autocomplete session
          // for billing purposes. Use this for Place Details requests that are called
          // following an autocomplete request in the same user session.

          // Generate new search token because we loaded Place Details
          this.generateSearchToken()
        }

      },
      error: () => {
        Swal.fire({
          icon: 'error',
          title: "Error loading business data",
          text: "Business data loading error"
        })
      }
    })
  }

  // alias: "chelsea-market-new-york"
  // categories: [{alias: "publicmarkets", title: "Public Markets"}] (1)
  // coordinates: {latitude: 40.7419711362098, longitude: -74.0048450364568}
  // display_phone: "(212) 652-2121"
  // hours: [Object] (1)
  // id: "jF5CrF1BmvhoPlMTp5AoJQ"
  // image_url: "https://s3-media2.fl.yelpcdn.com/bphoto/8RN1IL1y_D_1uviw1NuUZQ/o.jpg"
  // is_claimed: true
  // is_closed: false
  // location: {address1: "75 9th Ave", address2: "", address3: null, city: "New York", zip_code: "10011", …}
  // messaging: {url: "https://www.yelp.com/raq/jF5CrF1BmvhoPlMTp5AoJQ?ad…kup&utm_source=hR-88z9fYKBvowK5L1VLMA#popup%3Araq", use_case_text: "Message the Business"}
  // name: "Chelsea Market"
  // phone: "+12126522121"
  // photos: ["https://s3-media2.fl.yelpcdn.com/bphoto/8RN1IL1y_D_1uviw1NuUZQ/o.jpg", "https://s3-media1.fl.yelpcdn.com/bphoto/FDWkyyglx0ZFr9-kPQUAbg/o.jpg", "https://s3-media2.fl.yelpcdn.com/bphoto/XI28iFGk0W5iaah18ZzWeA/o.jpg"] (3)
  // price: "$$"
  // rating: 4.5
  // review_count: 2221
  // transactions: [] (0)
  // url: "https://www.yelp.com/biz/chelsea-market-new-york?adjust_creative=hR-88z9fYKBvowK5L1VLMA&utm_campaign=yelp_api_v3&utm_medium=api_v3_business_…"

  fillYelp({ categories, coordinates, id, image_url,
    phone,
    price, rating, review_count, url,
    location, name }) {

    $("#business_title").val(name || '');

    let cats = [];
    if (categories) {
      categories.forEach(({ title }) => {
        if (title || title !== '') {
          cats.push(title)
        }
      })
    }
    $("#business_categories").val(cats).trigger('change');

    if (location) {
      if (location.display_address) {
        $("#business_autocomplete_address").val(location.display_address.join(' '));
      }

      if (location.address1 && location.address1 !== '') {
        $("#business_address1").val(location.address1);
      }

      if (location.city && location.city !== '') {
        $("#business_city").val(location.city);
      }

      if (location.zip_code && location.zip_code !== '') {
        $("#business_zip").val(location.zip_code);
      }

      if (location.state && location.state !== '') {
        $("#business_state").val(location.state);
      }
    }
    $("#business_country").val("US");

    // Remove +1 prefix
    if (phone && phone !== '') {
      phone = phone.replace(/\+1/, '');
      $("#business_phone").val(phone);
    }

    $("#business_price").val(price);

    // console.log('yelp rating', rating);
    // console.log('yelp selector', `[name='business[rating]'][value='${rating.toFixed(1)}']`);

    if (rating) {
      $(`[name='business[rating]'][value='${rating.toFixed(1)}']`).trigger('click');
      $("#business_rating").val(rating);
    } else {
      this.clearYelpRating();
    }
    $("#business_yelp_reviews_count").val(review_count);
    $("#business_url").val(url);

    if (coordinates) {
      $("#business_location").val(`(${coordinates.latitude}, ${coordinates.longitude})`);
    }

    if (image_url) {
      $("#business_image_url").val(image_url);
    }

    $("#business_yelp_id").val(id);
  }

  // address_components: [{long_name: "75", short_name: "75", types: ["street_number"]}, {long_name: "9th Avenue", short_name: "9th Ave", types: ["route"]}, {long_name: "Manhattan", short_name: "Manhattan", types: ["sublocality_level_1", "sublocality", "political"]}, {long_name: "New York", short_name: "New York", types: ["locality", "political"]}, {long_name: "New York County", short_name: "New York County", types: ["administrative_area_level_2", "political"]}, {long_name: "New York", short_name: "NY", types: ["administrative_area_level_1", "political"]}, {long_name: "United States", short_name: "US", types: ["country", "political"]}, {long_name: "10011", short_name: "10011", types: ["postal_code"]}] (8)
  // adr_address: "<span class=\"street-address\">75 9th Ave</span>, <span class=\"locality\">New York</span>, <span class=\"region\">NY</span> <spa…"
  // business_status: "OPERATIONAL"
  // formatted_address: "75 9th Ave, New York, NY 10011, USA"
  // geometry: {location: {lat: 40.7424396, lng: -74.0061439}, viewport: {northeast: {lat: 40.74434345, lng: -74.00389415000001}, southwest: {lat: 40.74079485, lng: -74.00831434999999}}}
  // icon: "https://maps.gstatic.com/mapfiles/place_api/icons/shopping-71.png"
  // name: "Chelsea Market"
  // photos: [Object, Object, Object, Object, Object, Object, Object, Object, Object, Object] (10)
  // place_id: "ChIJw2lMFL9ZwokRosAtly52YX4"
  // plus_code: {compound_code: "PXRV+XG New York, NY, United States", global_code: "87G7PXRV+XG"}
  // types: ["shopping_mall", "point_of_interest", "establishment"] (3)
  // url: "https://maps.google.com/?cid=9106689863996784802"
  // utc_offset: -240
  // vicinity: "75 9th Avenue, New York"

  fillGoogle({
    result: {
      address_components,
      formatted_address,
      geometry,
      name,
      photos,
      place_id,
      types,
      url,
      rating,
      user_ratings_total,
      price_level,
    }
  }) {

    $("#business_title").val(name || '');

    let cats = [];
    // types.forEach((title) => {
    //   cats.push(title)
    // })
    $("#business_categories").val(cats).trigger('change');

    if (formatted_address && formatted_address !== '') {
      $("#business_autocomplete_address").val(formatted_address);
    }

    let address1 = '', city, zip, state, country;

    if (address_components) {
      address_components.forEach(({ long_name, short_name, types }) => {
        if (types.includes("street_number") || types.includes('route')) {
          address1 = address1 + (short_name || long_name);
        } else if (types.includes('locality')) {
          city = short_name
        } else if (types.includes('administrative_area_level_1')) {
          state = short_name
        } else if (types.includes('postal_code')) {
          zip = (short_name || long_name)
        } else if (types.includes('country')) {
          country = (short_name || long_name)
        }
      })
    }

    $("#business_address1").val(address1);
    $("#business_city").val(city);
    $("#business_zip").val(zip);
    $("#business_state").val(state);
    $("#business_country").val(country);

    // $("#business_phone").val('');

    let price_val = Array.from({length: price_level}, () => "$").join("");

    $("#business_price").val(price_val);

    // round rating to nearest half, 4.6 -> 4.5
    if (rating) {
      rating = Math.round(rating*2)/2;

      // console.log("google rating", rating);

      $(`[name='business[google_rating]'][value='${rating.toFixed(1)}']`).trigger('click');
      $("#business_google_rating").val(rating);
    } else {
      this.clearGoogleRating();
    }

    $("#business_google_reviews_count").val(user_ratings_total);

    $("#business_google_url").val(url || '');

    if (geometry && geometry.location) {
      $("#business_location").val(`(${geometry.location.lat}, ${geometry.location.lng})`);
    }

    // $("#business_image_url").val('');
    $("#business_google_id").val(place_id);
    // $("#business_yelp_id").val('');
  }
}
