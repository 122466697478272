import { onReady } from '../common';

let listenFormSubmit = () => {
  $(document).on('ajax:complete', '#new_importers_csv', (ev) => {
    // console.log(ev);
    // console.log(ev.detail);
    if (ev.detail[1] === "OK") {

    } else {
      $("#new-import-preview").empty().html(ev.detail[0].response);
    }
  })
};

onReady(() => {
  listenFormSubmit();
});
