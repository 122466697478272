import { onReady } from '../common';
import { noteIcon, renderNote } from '../note.js';
import { renderResult } from '../result.js';
import { isoToTableCellNY, isoToDateTimeNY } from '../time';
import { AppointmentSet, AppointmentCancelled, NoteAdded, Callback, ContactAdded } from '../call_results';

let businessActivityReportsTable = () => {
  return $("table.business-activity-reports").DataTable({
    retrieve: true,
    ajax: {
      url: $("table.business-activity-reports").data('url')
    },
    deferRender: true,
    order: [[0, 'desc']],
    columns: [
      // -# Date/Time  	Agent		Result 		Note		Actions (dot menu)
      {
        data: "created_at", render: (data, type, row) => {
          if (type === 'sort') {
            return row.created_at;
          };

          return isoToTableCellNY(row.created_at);
        }
      },
      {
        data: "creator", render: (data, type, row) => {
          return `${row.creator_name || ''}`;
        }
      },
      {
        data: "result", render: (data, type, row) => {
          return renderResult(row);
        }
      },
      {
        data: "note", render: (data, type, row) => {
          if (row.result == NoteAdded) {
            return noteIcon(row.note);
          }
          return noteIcon(row.call_note);
        }
      },
      {
        data: "actions", render: (data, type, row) => {
          // have a burger menu with actions  ( Change Meeting /  Cancel Meeting  or Change Call Back / Cancel Call Back)

          if (row.result == AppointmentSet && row.appointment_status !== AppointmentCancelled) {

            return `<div class="dropdown dropdown-inline contacts-actions">
              <button class="btn btn-hover-brand btn-elevate-hover btn-icon btn-sm btn-icon-md"
                type="button" id="contactsDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="flaticon-more"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="contactsDropdownMenuButton">

                <a class="dropdown-item edit-appointment" data-remote='true' href="/appointments/${row.appointment_id}/edit">
                  <i class="la la-pencil"></i>
                  Change Meeting
                </a>

                <a class="dropdown-item cancel-appointment" data-remote='true' data-confirm='Cancel Meeting?' data-method='delete' href="/appointments/${row.appointment_id}">
                  <i class="la la-remove"></i>
                  Cancel Meeting
                </a>

              </div>
            </div>
            `;

          };

          if (row.result == Callback) {
            return `<div class="dropdown dropdown-inline contacts-actions">
              <button class="btn btn-hover-brand btn-elevate-hover btn-icon btn-sm btn-icon-md"
                type="button" id="contactsDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="flaticon-more"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="contactsDropdownMenuButton">

                <a class="dropdown-item edit-callback" data-remote='true' href="/callbacks/${row.call_id}/edit">
                  <i class="la la-pencil"></i>
                  Edit Callback
                </a>

                <a class="dropdown-item cancel-callback" data-remote='true' data-confirm='Cancel Callback?' data-method='delete' href="/callbacks/${row.call_id}">
                  <i class="la la-remove"></i>
                  Cancel Callback
                </a>

              </div>
            </div>
            `;
          }

          return ``;
        }
      }
    ]
  });
};

onReady(() => {
  businessActivityReportsTable();

  $(document).on('ajax:complete', 'form.new_call', () => {
    businessActivityReportsTable().ajax.reload();
  });
});
