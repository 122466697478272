let { parseISO, format, formatDistance } = require('date-fns');
const { utcToZonedTime } = require('date-fns-tz');

const nyTZ = 'America/New_York';

let isoToTableCellNY = (datetime) => {
  if (!datetime) return '';

  let parsed = utcToZonedTime(parseISO(datetime), nyTZ);
  let date = format(parsed, 'MM/dd/yyyy');
  let time = format(parsed, 'hh:mm aa');
  return `<div title='${datetime}'>
  ${date}
  <div class='text-muted'>${time}</div>
  </div>`;
};

let isoToDateTimeNY = (datetime) => {
  if (!datetime) return '';

  let parsed = utcToZonedTime(parseISO(datetime), nyTZ);
  let date = format(parsed, 'MM/dd/yyyy');
  let time = format(parsed, 'hh:mm aa');

  return `${date} ${time}`;
}

let lastActivityAgo = () => {
  $(".last-activity").each((_, el) => {
    let time = $(el).data('time')
    if (!time) return;

    try {
      time = new Date(time)

      let timeVague = formatDistance(time, new Date())
      if (timeVague) {
        $(el).text("(" + timeVague + " ago)")
      }
    } catch(err) {}
  })
}

module.exports = {
  isoToTableCellNY,
  isoToDateTimeNY,
  lastActivityAgo
}
