
import { onReady, initializePhoneMask, initializeDatepicker, initializeDatetimepicker } from '../common';
import { noteIcon, renderNote } from '../note.js';
import { renderResult } from '../result.js';
import { isoToTableCellNY, isoToDateTimeNY } from '../time';
import { AppointmentSet, NoteAdded, Callback, ContactAdded } from '../call_results';
import { addressAutocomplete } from '../map';
import Swal from 'sweetalert2';

let callbackReportsTable = () => {
  let tbl = $("table.callback-reports").DataTable({
    retrieve: true,
    deferRender: true,
    ajax: {
      url: $("table.callback-reports").data('url')
    },
    createdRow: function( row, data, dataIndex ) {
      $( row ).addClass(`call-${data.call_id}`);
    },
    order: [[1, 'asc']],
    initComplete: () => {
      let api = $('table.callback-reports').DataTable();
      let container = api.table().container();

      $(container).find('.completed-filter').html(`
        <div class="dropdown">
          <button class="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Pending
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="">
            <a class="dropdown-item search" data-search="All" href="javascript:;"><i class="la la-list-alt"></i> All records</a>
            <a class="dropdown-item search active bg-light text-primary" data-search="Pending" href="javascript:;"><i class="la la-stop"></i> Pending</a>
            <a class="dropdown-item search" data-search="Completed" href="javascript:;"><i class="la la-check"></i> Completed</a>
          </div>
        </div>
      `);

      $(container).find('.completed-filter .search').on('click', (ev) => {
        let a = $(ev.target);

        api.table().column(0).search(a.data('search'), false).draw();
        a.closest('.dropdown').find('.dropdown-toggle').text(a.text());
        a.closest('.dropdown-menu').find('a').removeClass('active text-primary bg-light');
        a.addClass('active text-primary bg-light');
      });

      api.table().column(0).search("Pending", false).draw();
    },
    dom: "<'row'<'col-sm-12 col-md-2 d-flex align-items-center'l><'col-12 col-md-4 completed-filter d-flex align-items-center'><'col-sm-12 col-md-6 d-flex justify-content-end align-items-center'f>>" +
    "<'row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-12 col-md-2'l><'col-sm-12 col-md-3 d-flex align-items-center'i><'col-sm-12 col-md-7'p>>",
    columns: [
      {
        orderable: false,
        render: (data, type, row) => {
          if (type === 'filter') {
            if (row.callback_completed_at) {
              return "All Completed";
            } else {
              return "All Pending";
            }
          };

          let checked = row.callback_completed_at ? 'checked' : '';

          return $(`
            <div class='d-flex justify-content-center'>
            <label class="kt-checkbox kt-checkbox--single kt-checkbox--solid kt-checkbox--success">
              <input type="checkbox" value="${row.call_id}" ${checked} class="kt-checkable callback-completion-checkbox">
              <span></span>
            </label></div>`).prop('outerHTML');
        }
      },
      {
        data: "callback_at", render: (data, type, row) => {
          if (type === 'sort') return row.callback_at;

          return isoToTableCellNY(row.callback_at);
        }
      },
      {
        data: "business.name", render: (data, type, row) => {
          return `<a href='/businesses/${row.business_id}'>${row.business_title}</a>
          <div class='text-muted'>${row.business_address || ''}</div>`;
        }
      },
      {
        width: "30%",
        data: "phone", render: (data, type, row) => {
          if (type === 'sort') {
            return row.created_at;
          };

          let note = noteIcon(row.call_note);
          if (row.result == NoteAdded) {
            note = noteIcon(row.note);
          }

          return `${row.business_phone || ''}
          <div class='mt-2'>${note}</div>`;
        }
      },
      {
        data: "creator", render: (data, type, row) => {
          if (type === 'sort') return row.created_at;

          let t = isoToDateTimeNY(row.created_at);

          return `${row.creator_name || ''}
          <div class='text-muted'>${t}</div>`;
        }
      },
    ]
  });

  tbl.on('draw', callbackCompletedHandler);
};

let callbackCompletedHandler = () => {
  $('.callback-completion-checkbox').off();
  $('.callback-completion-checkbox').on('change', (ev) => {
    let i = $(ev.target);
    let callback_completed = i.prop('checked');
    let callId = i.val();

    $.ajax({
      url: `/calls/${callId}`,
      type: 'patch',
      data: { call: { callback_completed }},
      success: () => {
        let tbl = $("table.callback-reports").DataTable();
        let row = tbl.row(`.call-${callId}`);
        let data = row.data();

        if (callback_completed) {
          data.callback_completed_at = new Date();
        } else {
          data.callback_completed_at = null;
        }

        row.data(data);
        row.invalidate();
        tbl.draw();
      },
      error: () => {
        Swal.fire({
          icon: 'error',
          title: "Error updating callback"
        });

        throw new Error("Error updating callback completion");
      }
    });
  });
};

onReady(() => {
  callbackReportsTable();
  callbackCompletedHandler();
});
