
const NOTE_TRUNCATE_LENGTH = 200;

let renderNote = (note) => {
  if (!note || note == '') return '';

  if (note.length <= NOTE_TRUNCATE_LENGTH) {
    return $('<span />').addClass('word-wrap note').text(note).prop('outerHTML');
  }

  let toggleFull = $("<a />").addClass('note-expand').html('...').attr('href', 'javascript:void(0)');
  let noteWrapper = $("<span />").addClass('word-wrap note').attr('data-note', note).text(note.substring(0, NOTE_TRUNCATE_LENGTH-3)).append(toggleFull);

  return noteWrapper.prop('outerHTML');
};

let noteIcon = (note) => {
  if (!note || note == '') return '';

  let i = $('<i>').attr('data-feather', 'info').addClass('text-info');

  return $('<div>').addClass('text-999 d-inline-block').attr('data-toggle', 'tooltip')
    .attr('title', note).html(i)
    .prop('outerHTML');
};

module.exports = {
  renderNote,
  noteIcon
}
