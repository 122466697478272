let $ = require('jquery');
import { onReady } from '../common';
import Swal from 'sweetalert2';
import consumer from '../channels/consumer';
import { isoToDateTimeNY } from '../time';
import { ImportTask } from './import_task';

require('datatables.net-select-bs4');
require('datatables.net-buttons-bs4');

let listenImport = () => {
    let importId = $(".import-preview-btn").data('importId');

    let onProgress = ({ percentage }) => {
        if (percentage == 100) {
            $('.import-progress .progress-bar').text(`Complete`).attr('style', `width: ${percentage}%`).removeClass("progress-bar-animated progress-bar-striped").addClass("bg-success");
        } else {
            $('.import-progress .progress-bar').text(`${percentage}%`).attr('style', `width: ${percentage}%`);
        }
    }

    let onComplete = ({ total, success, errors }) => {
        let html = `
      <p>Total: ${total}</p>
      <p>Success: ${success}</p>
      <p>Errors: ${errors}</p>`

        Swal.fire({
            icon: (errors == 0 ? 'success' : 'warning'),
            title: 'Import complete',
            html: html
        })

        $(".import-preview-btn").hide();

        if ($("#new_yelp_search_query").length === 0) return;

        $("#new_yelp_search_query [name='yelp_search_query[last_run]']").remove()

        let lastRun = $("<input>").attr({
            'name': 'yelp_search_query[last_run]',
            'value': new Date().toString(),
            'type': 'hidden'
        })
        $("#new_yelp_search_query").append(lastRun)
    }

    new ImportTask({ consumer, importId, onProgress, onComplete });

    // Import Search Query button
    $(".import-preview-btn").off("click");
    $(".import-preview-btn").on("click", (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        let href = $(ev.target).attr('href');

        $(ev.target).addClass('disabled');

        $('.import-progress').removeClass('d-none').addClass('d-block');
        $('.import-progress .progress-bar').text('0%').attr('style', `width: 0%`);

        $.ajax(href, {
            type: 'post',
            complete: () => {
                $(ev.target).removeClass('disabled');
            },
            success: (resp) => {},
            error: (err) => {
                Swal.fire({
                    icon: 'error',
                    title: "Error",
                    text: "Import Error"
                })
            }
        })
    });
};

let queriesDatatable = () => {
    $("table.yelp-search-queries").DataTable({
        retrieve: true,
        ajax: $("table.yelp-search-queries").data('url'),
        dom: "<'row'<'col-sm-12 col-md-6'lB><'col-sm-12 col-md-6'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        select: {
            style: 'os',
            selector: 'td:first-child'
        },
        pageLength: 50,
        order: [
            [3, 'desc']
        ],
        buttons: [
            'selectAll',
            'selectNone',
            {
                extend: 'selected',
                className: "btn btn-success",
                text: "Enable",
                action: (e, dt, node, config) => {
                    // dt.ajax.reload();

                    let ids = dt.rows({ selected: true }).data().pluck('id').toArray();
                    $.ajax('/yelp_searches/enable', {
                        type: 'post',
                        data: { ids: ids },
                        complete: () => { dt.ajax.reload(); }
                    });
                }
            },
            {
                extend: 'selected',
                className: "btn btn-info",
                text: "Disable",
                action: (e, dt, node, config) => {
                    // dt.ajax.reload();

                    let ids = dt.rows({ selected: true }).data().pluck('id').toArray();
                    $.ajax('/yelp_searches/disable', {
                        type: 'post',
                        data: { ids: ids },
                        complete: () => { dt.ajax.reload(); }
                    });
                }
            },
            {
                extend: 'selected',
                className: "btn btn-danger",
                text: "Remove",
                action: (e, dt, node, config) => {
                    // dt.ajax.reload();

                    let ids = dt.rows({ selected: true }).data().pluck('id').toArray();

                    let r = "queries";
                    if (ids.length == 1) r = "query";

                    Swal.fire({
                        icon: 'warning',
                        title: "Are you sure?",
                        text: `Remove ${ids.length} ${r}?`,
                        showCancelButton: true,
                    }).then(({ value }) => {
                        if (!value) return;

                        $.ajax('/yelp_searches/remove', {
                            type: 'post',
                            data: { ids: ids },
                            complete: () => { dt.ajax.reload(); }
                        });
                    });
                }
            },
        ],
        columns: [{
                orderable: false,
                className: 'select-checkbox',
                data: null,
                render: () => { return "" }
            },
            {
                data: "term",
                render: (data, type, row) => {
                    // = link_to (q.term.presence || "<empty>"), edit_admin_yelp_search_path(q)
                    return `
          <a href='/yelp_searches/${row.id}/edit'>${data}</a>
          `
                }
            },
            { data: "location" },
            {
                data: "enabled",
                render: (data, type, row) => {
                    // = link_to (q.enabled? ? "Y" : "N"), toggle_enabled_admin_yelp_search_path(q), data: { remote: true, method: "post" }

                    let text = "N";
                    if (data) {
                        text = "Y";
                    };

                    return `<a href='/yelp_searches/${row.id}/toggle_enabled'
          data-remote='true' data-method='post'>
          ${text}
        </a>`
                }
            },
            {
                data: "last_run",
                render: (data, type, row) => {
                    if (type == 'sort') return data;
                    return isoToDateTimeNY(data);
                }
            },
            {
                data: null,
                render: (data, type, row) => {
                    //   = link_to edit_admin_yelp_search_path(q), class: "btn btn-link" do
                    //   %i{data: { feather: 'edit' }}

                    // = link_to admin_yelp_search_path(q), data: { confirm: "Are you sure to remove this search?", method: "delete" }, class: "btn btn-link text-danger" do
                    //   %i{data: { feather: "trash-2" }}

                    let edit = `<a href='/yelp_searches/${row.id}/edit' class='btn btn-link'>
          <i data-feather='edit'></i>
        </a>`;

                    let remove = `<a href='/yelp_searches/${row.id}' class='btn btn-link text-danger'
          data-method='delete' data-confirm='Are you sure to remove this search?'>
          <i data-feather='trash-2'></i>
        </a>`;

                    return `${edit} ${remove}`;
                }
            }
        ]
    });
};

onReady(() => {
    $('#query-preview').off('click');
    $('#query-preview').on('click', (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        $(ev.target).addClass('disabled');

        let data = $(ev.target).closest('form').serialize();
        let href = $(ev.target).data('href');
        $.getJSON(href, data, (response) => {
            $('#query-preview-results').html(response.html);
            listenImport();
            $(ev.target).removeClass('disabled');
        }).fail(err => {
            console.error(err);
            $('#query-preview-results').html(`Error loading results: ${err.responseText}`);
            $(ev.target).removeClass('disabled');
        })
    });

    $("#parse-yelp-url").off("click");
    $("#parse-yelp-url").on("click", () => {
        let v = $('#yelp-url').val();
        if (!v) {
            return;
        };

        let url = new URL(v);
        let params = url.searchParams;
        let term = params.get('find_desc');
        let location = params.get('find_loc');

        $("#yelp_search_query_term").val(term);
        $("#yelp_search_query_location").val(location);

        // let attrs = params.get('attrs').split(',');
        // console.debug(attrs);

        // for(let i =0; i < attrs.length; i++) {
        //   let a = attrs[i];
        //   let priceMatch = a.match(/RestaurantsPriceRange2\.(\d)/);
        //   if (priceMatch) {
        //     // console.log("price", priceMatch);
        //   }
        // };
    });

    queriesDatatable();
})