import { onReady, initializePhoneMask, initializeDatepicker, initializeDatetimepicker } from '../common'
import { noteIcon, renderNote } from '../note.js'
import { renderResult } from '../result.js'
import { isoToTableCellNY, isoToDateTimeNY } from '../time'
import { AppointmentSet, NoteAdded, Callback, ContactAdded } from '../call_results'
import { addressAutocomplete } from '../map'

require('jszip')
require('datatables.net-buttons-bs4')
require('datatables.net-buttons/js/buttons.html5.js')

const activityReportsTable = () => {
  const tbl = $('table.activity-reports').DataTable({
    retrieve: true,
    ajax: {
      url: $('table.activity-reports').data('url')
    },
    deferRender: true,
    order: [[0, 'desc']],
    buttons: [
      {
        extend: 'csvHtml5',
        title: 'Activity Report',
        text: 'Download',
        exportOptions: {
          orthogonal: 'export',
          columns: ['.csv']
        }
      }
    ],
    columns: [
      {
        data: 'created_at',
        className: 'csv',
        render: (data, type, row) => {
          if (type === 'sort') {
            return row.created_at
          };

          return isoToTableCellNY(row.created_at)
        }
      },
      {
        data: 'creator',
        className: 'csv',
        render: (data, type, row) => {
          return `${row.creator_name || ''}`
        }
      },
      {
        data: 'note',
        className: 'csv',
        render: (data, type, row) => {
          if (type === 'export') {
            if (row.result === NoteAdded) {
              return row.note
            }
            return row.call_note
          }

          if (row.result === NoteAdded) {
            return noteIcon(row.note)
          }
          return noteIcon(row.call_note)
        }
      },
      {
        data: 'business_title',
        render: (data, type, row) => {
          if (type === 'export') {
            return row.business_title
          }

          return `<a href='/businesses/${row.business_id}'>${row.business_title}</a>
            <div class='text-muted'>${row.business_phone || ''}</div>`
        }
      },
      {
        data: 'business_title',
        visible: false,
        className: 'csv'
      },
      {
        data: 'business_phone',
        visible: false,
        className: 'csv'
      },
      {
        data: 'business_address',
        render: (data, type, row) => {
          return `<div class='word-wrap'>${row.business_address}</div>
            <div class='text-muted'>${row.business_city || ''}, ${row.business_state || ''}</div>`
        }
      },
      {
        data: 'business_address',
        visible: false,
        className: 'csv'
      },
      {
        data: 'business_city',
        visible: false,
        className: 'csv'
      },
      {
        data: 'business_state',
        visible: false,
        className: 'csv'
      },
      {
        data: 'result',
        visible: false,
        className: 'csv'
      },
      {
        data: 'result',
        render: (data, type, row) => {
          return renderResult(row)
        }
      }
    ]
  })
}

const appointmentReportsTable = () => {
  const tbl = $('table.appointments-reports')
  tbl.DataTable({
    retrieve: true,
    ajax: {
      url: $('table.appointments-reports').data('url')
    },
    columns: [
      {
        data: 'calendar_name'
      },
      {
        data: 'datetime',
        render: (data, type, row) => {
          if (type === 'sort') return row.appointment_datetime

          return isoToTableCellNY(row.appointment_datetime)
        }
      },
      {
        data: 'business_title',
        render: (data, type, row) => {
          return `<a href='/businesses/${row.business_id}'>${row.business_title}</a>
          <div class='text-muted'>${row.business_address || ''}</div>`
        }
      },
      {
        data: 'appointment_contact',
        render: (data, type, row) => {
          return `${row.appointment_contact_name || ''}
          <div class='text-muted'>${row.appointment_contact_title || ''}</div>`
        }
      },
      {
        data: 'appointment_address',
        render: (data, type, row) => {
          return `${row.appointment_address || ''}`
        }
      },
      {
        data: 'creator',
        render: (data, type, row) => {
          if (type === 'sort') return row.created_at

          const t = isoToDateTimeNY(row.created_at)

          return `${row.creator_name || ''}
          <div class='text-muted'>${t}</div>`
        }
      },
      {
        data: 'note',
        render: (data, type, row) => {
          let note = noteIcon(row.call_note)
          if (row.result == NoteAdded) {
            note = noteIcon(row.note)
          };
          return note
        }
      }
    ]
  })
}

const editAppointment = () => {
  // Appointment modal initializers
  const placeholder = $('#modal-location .modal')

  $('#modal-location .select2').select2()
  initializeDatetimepicker({ scope: $('#modal-location') })
  initializePhoneMask({ scope: $('#modal-location') })
  initializeDatetimepicker({ scope: $('#modal-location .modal') })
  addressAutocomplete({ scope: $('#modal-location') })

  $('#modal-location form').on('ajax:complete', (ev) => {
    const response = ev.detail[0].response
    if (ev.detail[1] == 'OK') {
      $('#modal-location .modal').modal('hide')
    } else {
      $('#modal-location form.edit_call').replaceWith(response)
      editAppointment()
    };
  })

  // Appointment existing / new contact switch
  placeholder.find('.appointment-contact .toggle .btn').on('click', (ev) => {
    placeholder.find('.appointment-contact .toggle .btn').removeClass('active')
    $(ev.target).addClass('active')

    placeholder.find('.appointment-contact .existing-contact').addClass('d-none')
    placeholder.find('.appointment-contact .new-contact').addClass('d-none')
    const opened = $(ev.target).data('target')
    placeholder.find('.appointment-contact').find(opened).removeClass('d-none')

    placeholder.find('.select2').select2()

    // Clear selected existing contact when new contact wanted
    if (opened === '.new-contact') {
      initializePhoneMask({ scope: $('.new-contact') })
      placeholder.find("[name='call[appointment_attributes][contact_id]']").val('').trigger('change')
    }
  })
}

const editCallback = () => {
  initializeDatetimepicker({ scope: $('#modal-location') })
  // $('#modal-location .select2').select2();
  // initializePhoneMask({ scope: $("#modal-location") });

  $('#modal-location form').on('ajax:complete', (ev) => {
    const response = ev.detail[0].response
    if (ev.detail[1] == 'OK') {
      $('#modal-location .modal').modal('hide')

      // reload activity table
      $('.business-activity-reports').DataTable({
        retrieve: true
      }).ajax.reload()
    } else {
      $('#modal-location form.edit_call').replaceWith(response)
      editCallback()
    };
  })
}

onReady(() => {
  activityReportsTable()
  appointmentReportsTable()

  // Edit appointment
  $(document).off('ajax:complete', '.edit-appointment')
  $(document).on('ajax:complete', '.edit-appointment', (ev) => {
    const response = ev.detail[0].response
    $('#modal-location').html(`
      <div class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            ${response}
          </div>
        </div>
      </div>
    `)

    $('#modal-location').removeClass('d-none')

    $('#modal-location .modal').on('shown.bs.modal', () => {
      editAppointment()
    })

    $('#modal-location .modal').modal()
  })

  // Edit callback
  $(document).off('ajax:complete', '.edit-callback')
  $(document).on('ajax:complete', '.edit-callback', (ev) => {
    const response = ev.detail[0].response
    $('#modal-location').html(`
      <div class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Edit Callback</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              ${response}
            </div>
          </div>
        </div>
      </div>
    `)

    $('#modal-location').removeClass('d-none')

    // Callback Datetime Datepicker
    $('#modal-location .modal').on('shown.bs.modal', () => {
      editCallback()
    })

    $('#modal-location .modal').modal()
  })
})
