import { onReady } from '../common';

let initializeCalendarTable = () => {
  $(".admin-calendars").DataTable({
    retrieve: true,
    ajax: $('.admin-calendars').data('url'),
    columns: [
      {
        data: 'name'
      },
      {
        data: 'name',
        orderable: false,
        render: (data, type, row) => {
          let i = $('<i>').attr('data-feather', 'info');
          let info = $('<a>').addClass('text-info d-inline-block')
            .attr('data-toggle', 'tooltip')
            .attr('title', row.times).html(i);

          return info.prop('outerHTML');
        }
      },
      {
        data: 'future_appointments_count', render: (data, type, row) => {
          if (type == 'sort') {
            return row.future_appointments_count;
          };

          return `${row.future_appointments_count}
          <div class='text-muted'>${row.total_appointments}</div>`
        }
      },
      {
        data: null, render: (data, type, row) => {
          let archive = '';

          if (row.discarded_at) {
            archive = `<a href='/calendars/${row.id}/restore' data-toggle='tooltip' title='Restore' class='btn btn-link text-grey' data-method='patch'>
              <i data-feather='upload'></i>
            </a>`;
          } else {
            if (row.future_appointments_count > 0) {
              let s = row.future_appointments_count == 1 ? '' : 's';

              archive = `<a href='/calendars/${row.id}/archive' data-toggle='tooltip' title='Archive' class='btn btn-link text-danger'
                data-method='patch' data-confirm='Are you sure to archive this calendar? There are ${row.future_appointments_count} appointment${s} planned.'>
                <i data-feather='alert-circle' title='Archive'></i>
              </a>`;
            } else {
              archive = `<a href='/calendars/${row.id}/archive' data-toggle='tooltip' title='Archive' class='btn btn-link text-info'
                data-method='patch'>
                <i data-feather='archive'></i>
              </a>`;
            }
          };

          return `
          <a href='/calendars/${row.id}/edit' class='btn btn-link text-info'>
            <i data-feather='edit' title='Edit'></i>
          </a>
          ${archive}
          `
        }
      }
    ]
  })
};

onReady(() => {
  initializeCalendarTable();
});
