import { Controller } from "stimulus"
let $ = jQuery;

export default class extends Controller {
  static targets = ["sortButton", "sortField", "sortDirectionField", "zipFilter", "cityFilter"]

  connect() {
    this.updateDom()

    if (this.hasZipFilterTarget) {
      setTimeout(() => {
        $(this.zipFilterTarget).select2({
          placeholder: "",
          allowClear: true,
          ajax: {
            url: this.zipFilterTarget.dataset.url,
            data: (params) => {
              let cityValue = this.cityFilterTarget.value

              if (cityValue && cityValue !== '') {
                params.city = cityValue
              }
              return params
            }
          }
        })
      }, 1)
    }
  }

  // make current sort option active
  // and up or down icon visible
  updateDom() {
    let sortValue = this.sortFieldTarget.value

    this.sortButtonTargets.forEach((el) => {
      if (el.dataset.sort === sortValue) {
        el.classList.toggle('active', true)
        el.classList.toggle('text-success', true)

        let icon = el.querySelector('i')
        icon.classList.toggle('invisible', false)

        if (this.sortDirectionFieldTarget.value === "asc") {
          icon.classList.toggle('flaticon2-arrow-down', false)
          icon.classList.toggle('flaticon2-arrow-up', true)
        } else {
          icon.classList.toggle('flaticon2-arrow-down', true)
          icon.classList.toggle('flaticon2-arrow-up', false)
        }
      } else {
        el.classList.toggle('active', false)
        el.classList.toggle('text-success', false)

        el.querySelectorAll('i').forEach((icon) => {
          icon.classList.toggle('invisible', true)
        })
      }
    })
  }

  changeSort(ev) {
    let sort = ev.currentTarget.dataset.sort
    let currentSort = this.sortFieldTarget.value

    if (currentSort === sort) {
      // Flip direction only
      if (this.sortDirectionFieldTarget.value === "asc") {
        this.sortDirectionFieldTarget.value = "desc"
      } else {
        this.sortDirectionFieldTarget.value = "asc"
      }
    } else {
      this.sortFieldTarget.value = sort
      this.sortDirectionFieldTarget.value = "asc"
    }
    this.updateDom()
  }
}
