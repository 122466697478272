if (window.Sentry) {
  Sentry.init({ dsn: 'https://c5458f3d65174c0fb7ded17014ccf6f4@sentry.io/1885706' });
}

const feather = require('feather-icons');
const { differenceInMilliseconds, differenceInMinutes } = require('date-fns');
require('inputmask/dist/jquery.inputmask');
require('bootstrap-datetime-picker/js/bootstrap-datetimepicker');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('select2');
const { lastActivityAgo } = require('./time');

const defaults = {
  language: {
    paginate: {
      first: '<i class="la la-angle-double-left"></i>',
      last: '<i class="la la-angle-double-right"></i>',
      next: '<i class="la la-angle-right"></i>',
      previous: '<i class="la la-angle-left"></i>'
    }
  }
};

$.extend(true, $.fn.dataTable.defaults, defaults);

function onReady (cb) {
  // $.when($.ready).then(cb);
  $(document).on('ready turbolinks:load', cb);
}

const updateUnlock = () => {
  if ($('.unlock-at').length <= 0) return;

  $('.unlock-at').each((_, el) => {
    const t = $(el).data('datetime');
    const extendMinutes = $(el).data('extend-minutes');
    const extendBy = $(el).data('extend-by');

    const extendButton = `<a href='/city_locks/extend' data-method='post' data-remote='true'
      data-toggle='tooltip'
      title='Will add ${extendBy} minutes more'
      class='ml-3 btn btn-info extend-btn'>
      Extend Time
    </a>`;

    const minutesLeft = differenceInMinutes(new Date(t), new Date());
    const msecLeft = differenceInMilliseconds(new Date(t), new Date());
    // console.debug("minutes left", minutesLeft);
    // console.debug("msec left", msecLeft);
    if (minutesLeft <= 0 && msecLeft <= 0) {
      $(el).html('Claim expired');
    } else if (minutesLeft < extendMinutes) {
      $(el).html(`Will be unlocked in ${minutesLeft} minutes
        ${extendButton}`);
    } else {
      $(el).text(`Will be unlocked in ${minutesLeft} minutes`);
    }
  });
};

const reloadLockInfo = () => {
  const url = $('.lock-info').data('url');
  $.ajax(url, {
    success: (resp) => {
      $('.lock-info').empty().html(resp);
      unlockUpdater();
      $('.lock-info [data-toggle="tooltip"]').tooltip({
        container: 'body',
        html: true
      });
    }
  });
};

const extendButtonHandler = () => {
  $(document).off('ajax:complete', '.extend-btn');
  $(document).on('ajax:complete', '.extend-btn', () => {
    $('.extend-btn').tooltip('dispose');

    reloadLockInfo();
  });
};

let i;
const unlockUpdater = () => {
  if (i) {
    clearInterval(i);
  }
  i = setInterval(updateUnlock, 60000);
  updateUnlock();
};

const initializePhoneMask = ({ scope = undefined } = {}) => {
  if (scope) {
    scope.find('.phone-mask').inputmask({ mask: '(999) 999-9999' });
  } else {
    $('.phone-mask').inputmask({ mask: '(999) 999-9999' });
  }
};

const initializeDatetimepicker = ({ scope = null } = {}) => {
  const opts = {
    format: 'mm/dd/yyyy HH:ii P',
    autoclose: true,
    todayHighlight: true,
    minuteStep: 15,
    showMeridian: true
  };

  if (scope) {
    scope.find('.datetime-picker').datetimepicker('remove');
    scope.find('.datetime-picker').datetimepicker(opts);
  } else {
    $('.datetime-picker').datetimepicker('remove');
    $('.datetime-picker').datetimepicker(opts);
  }
};

const initializeDatepicker = ({ scope = null } = {}) => {
  const opts = {
    format: 'mm/dd/yyyy',
    autoclose: true,
    todayHighlight: true,
    minView: 'month'
  };

  if (scope) {
    scope.find('.date-picker').datetimepicker('remove');
    scope.find('.date-picker').datetimepicker(opts);
  } else {
    $('.date-picker').datetimepicker('remove');
    $('.date-picker').datetimepicker(opts);
  }
};

const clearRadio = ({ scope = null } = {}) => {
  scope = scope || $(document);

  // console.log("Clear Radio", scope);

  scope.find('.clear-radios').each((_i, el) => {
    const closestSelector = $(el).data('closest');
    const root = $(el).closest(closestSelector);

    // console.log("Radio", el, "root", root);

    $(el).off('click');
    $(el).on('click', () => {
      root.find('input[type=radio]').attr('checked', false).prop('checked', false);
    });
  });
};

$(document).on('turbolinks:before-visit  turbolinks:before-cache', () => {
  $($.fn.dataTable.tables(true)).DataTable({ retrieve: true }).destroy();
  $('.select2-hidden-accessible').select2('destroy');
  $('.phone-mask').inputmask('remove');
  $('.datetime-picker').datetimepicker('remove');
  $('.date-picker').datetimepicker('remove');
});

const clearStars = () => {
  $('.clear-stars').off('click');
  $('.clear-stars').on('click', (ev) => {
    const starsSelector = $(ev.target).data('stars');
    if (!starsSelector) return;

    const stars = $(starsSelector);
    stars.find('input[type=radio]').prop('checked', false);
    stars.find('input[type=hidden]').val('');
  });
};

onReady(() => {
  initializePhoneMask();

  // Tables
  $(document).on('draw.dt', (ev) => {
    feather.replace();
    $('[data-toggle="tooltip"]').tooltip({
      container: 'body',
      html: true
    });
  });

  $($.fn.dataTable.tables(true)).DataTable({ retrieve: true }).destroy();
  $('.select2-hidden-accessible').select2('destroy');

  feather.replace();
  $('.datatable').DataTable({
    retrieve: true
  });

  $('.select2').select2();
  $('.select2-tags').select2({
    tags: true
  });
  $('.select2-remote').each((_, el) => {
    $(el).select2({
      allowClear: true,
      placeholder: '',
      ajax: {
        url: $(el).data('url')
      }
    });
  });

  unlockUpdater();

  $('[data-toggle="tooltip"]').tooltip({
    container: 'body',
    html: true
  });

  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    $($.fn.dataTable.tables(true)).DataTable()
      .columns.adjust();

    // console.debug("tab changed", $.fn.dataTable.tables( { visible: true, api: true } ));
    // $.fn.dataTable.tables({ visible: true, api: true }).columns.adjust();
  });

  initializeDatepicker();
  initializeDatetimepicker();

  // Note expand
  $(document).on('click', '.note-expand', (ev) => {
    const s = $(ev.target).closest('span');
    s.text(s.data('note'));

    $($.fn.dataTable.tables(true)).DataTable()
      .columns.adjust();
  });

  // Extend Time buttons
  extendButtonHandler();

  // Allow table tags in tooltips
  const myDefaultWhiteList = $.fn.tooltip.Constructor.Default.whiteList;
  // To allow table elements
  myDefaultWhiteList.table = [];
  myDefaultWhiteList.tbody = [];
  myDefaultWhiteList.tfoot = [];
  myDefaultWhiteList.tr = [];
  myDefaultWhiteList.td = [];

  // Format last activity for Business
  lastActivityAgo();

  // Clear stars
  clearStars();

  // Initialize KTApp
  if (window.KTApp) {
    KTApp.init(KTAppOptions);
    KTLayout.init();
  }
});

module.exports = {
  onReady,
  reloadLockInfo,
  initializePhoneMask,
  initializeDatepicker,
  initializeDatetimepicker,
  clearRadio
};
