let $ = require('jquery');
import { onReady } from '../common';

onReady(() => {
  $(".businesses-table").DataTable({
    retrieve: true,
    serverSide: true,
    processing: true,
    ajax: {
      url: "/businesses"
    },
    columns: [
      {
        data: "title", render: function (data, type, row) {
          let categories = row.categories_text;
          return `${row.title}
          <div class='text-muted' style='white-space: normal'>${categories}</div>`;
        }
      },
      {
        data: "rating", render: function (data, type, row) {
          return `${row.rating || '&nbsp;'}
          <div class='text-muted'>${row.price || '&nbsp;'}</div>`;
        }
      },
      {
        data: "city", render: function (data, type, row) {
          return `${row.city}
          <div class='text-muted'>${row.state} (${row.zip})</div>`;
        }
      },
      {
        data: "status"
      },
      {
        data: "status", render: function (data, type, row) {
          return `<div class='text-muted'>Actions</div>`;
        }
      }
    ]
  });

});
