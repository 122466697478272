let mapLoaded = false;
let callbacks = [];

let whenMapReady = (cb) => {
  if (mapLoaded) {
    cb.call(this);
  } else {
    callbacks.push(cb);
  };
};

window.googleMapsLoaded = () => {
  mapLoaded = true;

  callbacks.forEach( (cb) => {
    cb.call(this);
  });
  callbacks = [];
};

let addressAutocomplete = ({ scope = null } = {}) => {
  var defaultBounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(49.38823, -128.07043),
    new google.maps.LatLng(23.49166, -64.6134));

  var input = scope.find('.address-autocomplete').first();
  if (input.length <= 0) return;

  $(input).on('keydown', (ev) => {
    if (ev.keyCode == 13) {
      ev.preventDefault();
      ev.stopPropagation();
      return false;
    }
  })

  let origin = $(input).data('origin');
  var options = {
    bounds: defaultBounds,
    origin: origin,
    types: ['address'],
    componentRestrictions: {country: 'us'}
  };

  let autocomplete = new google.maps.places.Autocomplete(input.get(0), options);

  autocomplete.addListener('place_changed', () => {
    let place = autocomplete.getPlace();

    $(".call_appointment_city input").val('');
    $(".call_appointment_state input").val('');
    $(".call_appointment_zip input").val('');

    if (!place || !place.geometry) return false;

    let l = place.geometry.location;
    if (l) {
      scope.find('.call_appointment_location input').val(`(${l.lat()},${l.lng()})`);
    } else {
      scope.find('.call_appointment_location input').val('');
    };

    place.address_components.forEach(({ long_name, short_name, types }) => {
      if (types.indexOf('administrative_area_level_1') > -1) {
        // State - short
        $(".call_appointment_state input").val(short_name);
      };

      if (types.indexOf('locality') > -1) {
        // City
        $(".call_appointment_city input").val(long_name);
      };

      if (types.indexOf('postal_code') > -1) {
        // Country
        $(".call_appointment_zip input").val(short_name);
      };
    });

  });
};

module.exports = {
  whenMapReady,
  addressAutocomplete
}
