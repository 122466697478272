let $ = require('jquery');
import { onReady, reloadLockInfo, initializePhoneMask, initializeDatetimepicker, clearRadio } from '../common';
import Swal from 'sweetalert2';
require('popper.js');
require('bootstrap');
import { whenMapReady, addressAutocomplete } from '../map';
const feather = require('feather-icons');
import { initializeCalendar } from './new_call_calendar';
import { lastActivityAgo } from '../time';

let map;
let markers = [];

let initMap = () => {
  if ($("#map").length <= 0) { return }

  let data = $("#map").data();

  map = new google.maps.Map(document.getElementById('map'), {
    center: { lat: data.centerLat, lng: data.centerLon },
    zoom: 10
  });

  let bounds = new google.maps.LatLngBounds();

  $(".business-card").each((_, el) => {
    let businessData = $(el).data();
    if (businessData.lon && businessData.lat) {
      let point = { lat: businessData.lat, lng: businessData.lon };
      bounds.extend(point);

      let marker = new google.maps.Marker({
        position: point,
        title: businessData.title,
        map: map
      });

      marker.addListener('mouseover', () => {
        $(".list-group-item").removeClass('active');
        $(el).closest('.list-group-item').addClass('active');
      });

      marker.addListener('mouseout', () => {
        $(".list-group-item").removeClass('active');
      })

      markers.push(marker);
    }
  })

  if (data.zoom) {
    map.setZoom(data.zoom);
  } else {
    if (!bounds.isEmpty()) {
      map.fitBounds(bounds);
    }
  };
}

let callResultSelect = ({ root, value }) => {
  let v = value;

  // root.find('.alert.alert-danger').remove();

  if (v === "CALL_BACK") {
    root.find(".callback").removeClass('d-none');
  } else {
    root.find(".callback").addClass('d-none');
  };

  if (v === "PERMANENTLY_CLOSED") {
    root.find(".permanently-closed").removeClass('d-none');
  } else {
    root.find(".permanently-closed").addClass('d-none');
  };

  if (v === "DONT_CALL_BACK") {
    root.find(".dont-call").removeClass('d-none');
  } else {
    root.find(".dont-call").addClass('d-none');
  };

  if (v === "APPOINTMENT_SET") {
    root.find(".appointment").removeClass('d-none');
    root.find(".appointment .select2").select2();

    addressAutocomplete({ scope: root });
    clearRadio({ scope: root });

    callInquiryHandler({ scope: root });
  } else {
    root.find(".appointment").addClass('d-none');
  }

  if (v === 'SOLD') {
    root.find(".sell").removeClass('d-none');
    root.find(".sell-contact .select2").select2();

    root.find(".sell-product.select2-hidden-accessible").select2('destroy');
    root.find(".sell-product").select2({
      width: '100%',
      tags: true,
      ajax: {
        url: '/sells/products'
      }
    });
  } else {
    root.find(".sell").addClass('d-none');
  }
};

let callInquiryHandler = ({ scope }) => {
  scope.off('click', '.call-inquiry-no');
  scope.off('click', '.call-inquiry-yes');

  scope.on('click', '.call-inquiry-no', () => {
    scope.find('.responded-to').hide();
  });

  scope.on('click', '.call-inquiry-yes', () => {
    scope.find('.responded-to').show();
  });

  if (scope.find('.call-inquiry-yes').prop('checked')) {
    scope.find('.responded-to').show();
  } else {
    scope.find('.responded-to').hide();
  }
};

// lgi = list group item
// it's a card of business info + controls (new call, new contact etc)
let controlsInitialize = ({ lgi, resp }) => {
  let placeholder = lgi.find('.controls-content');

  placeholder.empty().html(resp);
  placeholder.find('input:visible, textarea:visible').first().focus();

  placeholder.find("[data-dismiss=modal]").on('click', () => {
    placeholder.html('');
    lgi.find("[data-modal-href]").removeClass('active');
    lgi.removeClass('active');
  });

  initializeDatetimepicker({ scope: placeholder });

  feather.replace();
  lgi.find('[data-toggle=tooltip]').tooltip();

  placeholder.find(`input[name='call[result]']`).on('click', (ev) => {
    let value = $(ev.target).val();
    callResultSelect({ root: placeholder, value })
  });

  let callResult = placeholder.find(`input[name='call[result]']:checked`).first();
  if (callResult) {
    let value = callResult.val();
    callResultSelect({ root: placeholder, value });
  };

  // Appointment existing / new contact switch
  placeholder.find(".appointment-contact .toggle .btn").on("click", (ev) => {
    placeholder.find(".appointment-contact .toggle .btn").removeClass('active');
    $(ev.target).addClass('active');

    placeholder.find(".appointment-contact .existing-contact").addClass('d-none');
    placeholder.find(".appointment-contact .new-contact").addClass('d-none');
    let opened = $(ev.target).data('target');
    placeholder.find('.appointment-contact').find(opened).removeClass('d-none');

    // placeholder.find('.select2').select2();

    // Clear selected existing contact when new contact wanted
    if (opened === ".new-contact") {
      placeholder.find("[name='call[appointment_attributes][contact_id]']").val('').trigger('change');
    }
  });

  // Sell existing / new contact switch
  placeholder.find(".sell-contact .toggle .btn").on("click", (ev) => {
    placeholder.find(".sell-contact .toggle .btn").removeClass('active');
    $(ev.target).addClass('active');

    placeholder.find(".sell-contact .existing-contact").addClass('d-none');
    placeholder.find(".sell-contact .new-contact").addClass('d-none');
    let opened = $(ev.target).data('target');
    placeholder.find('.sell-contact').find(opened).removeClass('d-none');

    // Clear selected existing contact when new contact wanted
    if (opened === ".new-contact") {
      placeholder.find("[name='call[sell_attributes][contact_id]']").val('').trigger('change');
    }
  });

  initializePhoneMask({ scope: placeholder });

  // Update Calendar times tooltip when change selected calendar
  $(".calendar-select").off('change.dan');
  $(".calendar-select").on('change.dan', (ev) => {
    let option = $(ev.target).find(":selected");
    let tooltipEl = $(ev.target).find("~[data-toggle='tooltip']");
    let v = $(ev.target).val();

    tooltipEl.tooltip('dispose');

    if (v) {
      tooltipEl.attr('title', option.data('times'));
      tooltipEl.tooltip({
        container: 'body',
        html: true
      });
    }

    let calendarIcon = placeholder.find(".datetime-calendar-btn");

    let enabledClasses = 'btn-link';
    let disabledClasses = 'btn-disabled text-danger';

    if (v) {
      calendarIcon.removeClass(disabledClasses);
      calendarIcon.addClass(enabledClasses);
      calendarIcon.attr('title', `View calendar ${option.text()}`);
    } else {
      calendarIcon.removeClass(enabledClasses);
      calendarIcon.addClass(disabledClasses);
      calendarIcon.attr('title', "Please select a calendar");
    };
    calendarIcon.tooltip('dispose');
    calendarIcon.tooltip({
      html: true,
      container: 'body'
    });
  });
  $(".calendar-select").trigger('change.dan');

  // Select Datetime using Calendar in modal (see new_call_calendar.js)
  initializeCalendar({ form: placeholder.find('form') });

  // replace contents after form submit
  placeholder.find("form").on("ajax:complete", function (ev) {
    let controlsResponse = ev.detail[0].responseText;
    lgi.addClass('active');

    // reload business card (list group item) and reinitialize with response
    $.ajax({
      url: lgi.data('url'),
      type: 'get',
      async: false,
      success: (lgiResponse) => {
        let newLgi = $(lgiResponse);
        newLgi.addClass('active');
        setTimeout(() => {
          newLgi.removeClass('active');
        }, 2000);
        lgi.replaceWith(newLgi);
        modalHandler();
        controlsInitialize({ lgi: newLgi, resp: controlsResponse });
      }
    });

    // reload contacts table
    $(".contacts-table").DataTable({
      retrieve: true
    }).ajax.reload();

    // reload activity table
    $(".business-activity-reports").DataTable({
      retrieve: true
    }).ajax.reload();
  });

};

let modalHandler = () => {
  feather.replace();

  $('a[data-modal-href]').off('click');
  $('a[data-modal-href]').on('click', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    let lgi = $(ev.target).closest('.list-group-item');

    lgi.find("[data-modal-href]").removeClass('active');
    $(ev.target).addClass('active');

    let url = $(ev.target).data('modalHref');
    $.ajax({
      url,
      type: 'get',
      success: (resp) => {
        controlsInitialize({ lgi, resp });
      },
      error: () => {
        Swal.fire({
          icon: 'error',
          title: "Error loading template from server"
        })
      }
    });

    return false;
  });

};

let moreBusinesses = () => {
  $('.more-businesses, .prev-businesses').off('click');
  $('.more-businesses, .prev-businesses').on('click', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    let url = $(ev.target).attr('href');

    $.ajax({
      url,
      dataType: 'json',
      type: 'get',
      success: (resp) => {
        $('ul.businesses').html($(resp.template));
        modalHandler();
        initMap();

        $('.more-businesses').attr('href', resp.next);
        $('.prev-businesses').attr('href', resp.prev);

        // console.debug("prev", resp.prev, "more", resp.next);

        lastActivityAgo()

        if (resp.next) {
          $('.more-businesses').removeClass('d-none').addClass('d-block');
        } else {
          $('.more-businesses').removeClass('d-block').addClass('d-none');
        }

        if (resp.prev) {
          $('.prev-businesses').removeClass('d-none').addClass('d-block');
        } else {
          $('.prev-businesses').removeClass('d-block').addClass('d-none');
        }
      },
      error: () => {
        Swal.fire({
          icon: 'error',
          title: "Error loading more businesses"
        })
      }
    });
  });
};

let claimButton = () => {
  $(document).off('ajax:error', '.claim-btn');
  $(document).on('ajax:error', '.claim-btn', (ev) => {
    Swal.fire({
      icon: 'error',
      title: 'Error claiming the city',
      text: 'Please reload the page and try again'
    });

    throw new Error(`Error claiming the city ${ev.detail}`);
  });

  $(document).off('ajax:success', '.claim-btn');
  $(document).on('ajax:success', '.claim-btn', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    reloadLockInfo();
  });
};

let editContact = () => {

  // Edit appointment
  $(document).off('ajax:complete', '.edit-contact');
  $(document).on('ajax:complete', '.edit-contact', (ev) => {
    let response = ev.detail[0].response;
    $('#modal-location').html(`
      <div class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            ${response}
          </div>
        </div>
      </div>
    `);

    $('#modal-location').removeClass('d-none');

    // Appointment Datetime Datepicker
    $('#modal-location .modal').on('shown.bs.modal', () => {
      initializeDatetimepicker({ scope: $("#modal-location") });

      $('#modal-location .select2').select2();
      initializePhoneMask({ scope: $("#modal-location") });
    });

    $('#modal-location .modal').modal();
  });

  $(document).off('ajax:complete', "#modal-location form.edit_contact");
  $(document).on('ajax:complete', "#modal-location form.edit_contact", (ev) => {
    let res = ev.detail[0].response;
    // console.debug('response', res, ev.detail);
    if (ev.detail[1] == "OK") {
      $("#modal-location .modal").modal('hide');

      // Reload Contacts
      $('.contacts-table').DataTable({
        retrieve: true
      }).ajax.reload();

      // reload activity table
      $(".business-activity-reports").DataTable({
        retrieve: true
      }).ajax.reload();
    } else {
      $(".modal-content").html(res);
      editContact();
    }
  });

};

let contactsTable = () => {
  $('.contacts-table').DataTable({
    retrieve: true,
    ajax: {
      url: $('.contacts-table').data('url')
    },
    columns: [
      {
        data: "name", render: (data, type, row) => {
          return `${row.first_name || ''} ${row.last_name || ''}`;
        }
      },
      {
        data: "title", render: (data, type, row) => {
          return row.title || '';
        }
      },
      {
        data: "email", render: (data, type, row) => {
          return row.email || '';
        }
      },
      {
        data: 'phone', render: (data, type, row) => {
          return row.display_phone || '';
        }
      },
      {
        orderable: false,
        data: 'phone', render: (data, type, row) => {
          return `<div class="dropdown dropdown-inline contacts-actions">
            <button class="btn btn-hover-brand btn-elevate-hover btn-icon btn-sm btn-icon-md"
              type="button" id="contactsDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="flaticon-more"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="contactsDropdownMenuButton">
              <a class="dropdown-item edit-contact" data-remote="true"
                href="/contacts/${row.id}/edit">

                <i class="la la-pencil"></i>
                Edit Contact
              </a>
            </div>
          </div>`;
        }
      }
    ]
  });
};

onReady(() => {
  whenMapReady(initMap);
  // modals
  modalHandler();
  // more businesses load
  moreBusinesses();
  claimButton();
  contactsTable();
  editContact();
});
