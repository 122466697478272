import { onReady } from '../common';
import { debounce } from 'lodash';
import { whenMapReady } from '../map';

let runYelpSearch = () => {
  let title = $('#business_title').val();
  let address = $('#business_address1').val();
  if (title == '' || address == '') {
    $('#yelp-results').html('');
    return;
  };

  $.ajax('/yelp/search', {
    type: 'get',
    data: { address, title },
    dataType: 'json'
  }).then(res => {
    $('#yelp-results').html(res);
  }).catch(err => {
    $('#yelp-result').html(err);
  });
};

let runYelp = debounce(runYelpSearch, 500);

let addressAutocomplete = () => {
  if ($('#business_autocomplete_address').length <= 0) return;

  let input = $('#business_autocomplete_address').get(0);

  $(input).on('keydown', (ev) => {
    if (ev.keyCode == 13) {
      ev.preventDefault();
      ev.stopPropagation();
      return false;
    }
  });

  var defaultBounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(49.38823, -128.07043),
    new google.maps.LatLng(23.49166, -64.6134));

  let options = {
    bounds: defaultBounds,
    types: ['address']
  };

  let autocomplete = new google.maps.places.Autocomplete(input, options);

  autocomplete.addListener('place_changed', () => {
    let place = autocomplete.getPlace();
    // console.debug(place);

    if (!place || !place.geometry) return false;

    let l = place.geometry.location;
    if (l) {
      $('#business_location').val(`(${l.lat()},${l.lng()})`);
    } else {
      $('#business_location').val('');
    };

    $("#business_state").val('');
    $("#business_city").val('');
    $("#business_country").val('');
    $("#business_zip").val('');

    $("#business_address1").val(place.formatted_address);

    place.address_components.forEach(({ long_name, short_name, types }) => {
      if (types.indexOf('administrative_area_level_1') > -1) {
        // State - short
        $("#business_state").val(short_name);
      };

      if (types.indexOf('locality') > -1) {
        // City
        $("#business_city").val(long_name);
      };

      if (types.indexOf('country') > -1) {
        // Country
        $("#business_country").val(short_name);
      };

      if (types.indexOf('postal_code') > -1) {
        // Country
        $("#business_zip").val(short_name);
      };

    });
  });

};

onReady(() => {
  // $('#business_title, #business_address1').on('change keyup blur focus', () => {
  //   runYelp();
  // });

  whenMapReady(addressAutocomplete);

});
