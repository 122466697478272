let $ = require('jquery');
import { onReady } from '../common';
import { isoToDateTimeNY } from '../time';

onReady(() => {
  let url = $(".existing-customers-table").data('url');
  $(".existing-customers-table").DataTable({
    retrieve: true,
    serverSide: true,
    processing: true,
    pageLength: 50,
    ajax: {
      url: url
    },
    columns: [
      {
        data: "title", render: function (data, type, row) {
          let local = `${row.business.title}<br>${row.business.phone}`;
          let gbh = `${row.title}
          <div class='text-muted' style='white-space: normal'>${row.phone}</div>`;

          let tooltip = $("<div>").addClass('d-inline-block').attr('data-toggle', 'tooltip')
            .attr('title', local)
            .html(gbh);
          return tooltip.prop('outerHTML');
        }
      },
      {
        data: "address", render: function (data, type, row) {
          let local = `${row.business.address1}<br>${row.business.zip}`;
          let gbh = `${row.address}
          <div class='text-muted' style='white-space: normal'>${row.zip}</div>`;

          let tooltip = $("<div>").addClass('d-inline-block').attr('data-toggle', 'tooltip')
            .attr('title', local)
            .html(gbh);
          return tooltip.prop('outerHTML');
        }
      },
      {
        data: "match", render: function (data, type, row) {
          let icon = `<i class='la la-phone' ></i>`;
          if (row.match == 'address') {
            icon = `<i class='la la-home'></i>`;
          };

          return `
          <a class='d-block h3' target='gbh' href='${row.gbh_url}'>
            ${icon}
          </a>`;
        }
      },
      {
        data: "expires", render: function (data, type, row) {
          if (!row.expires) return '';
          return isoToDateTimeNY(row.expires);
        }
      },
      {
        data: "match", render: function (data, type, row) {
          return `<div class='text-muted h3'>
            <i class='la la-ellipsis-h'> </i>
          </div>`;
        }
      }
    ]
  });

});
