import { onReady } from '../common'
import { noteIcon, renderNote } from '../note.js'
import { renderResult } from '../result.js'
import { isoToTableCellNY, isoToDateTimeNY } from '../time'
import { AppointmentSet, NoteAdded, Callback, ContactAdded } from '../call_results'

const adminActivityReportsTable = () => {
  const tbl = $('table.admin-activity-reports').DataTable({
    retrieve: true,
    ajax: {
      url: $('table.admin-activity-reports').data('url')
    },
    deferRender: true,
    order: [[0, 'desc']],
    columns: [
      {
        data: 'created_at',
        render: (data, type, row) => {
          if (type === 'sort') {
            return row.created_at
          };

          return isoToTableCellNY(row.created_at)
        }
      },
      {
        data: 'creator',
        render: (data, type, row) => {
          return `${row.creator_name || ''}`
        }
      },
      {
        data: 'note',
        render: (data, type, row) => {
          if (row.result == NoteAdded) {
            return noteIcon(row.note)
          }
          return noteIcon(row.call_note)
        }
      },
      {
        data: 'business_title',
        render: (data, type, row) => {
          return `<a href='/businesses/${row.business_id}'>${row.business_title}</a>
            <div class='text-muted'>${row.business_phone || ''}</div>`
        }
      },
      {
        data: 'business_address',
        render: (data, type, row) => {
          return `<div class='word-wrap'>${row.business_address}</div>
            <div class='text-muted'>${row.business_city || ''}, ${row.business_state || ''}</div>`
        }
      },
      {
        data: 'result',
        render: (data, type, row) => {
          return renderResult(row)
        }
      }
    ]
  })
}

onReady(() => {
  adminActivityReportsTable()
})
