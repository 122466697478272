import { Controller } from "stimulus"
let $ = jQuery;

export default class extends Controller {
  static targets = ["cities", "state", "stateSelect"]

  connect() {
    if (!this.hasStateSelectTarget) {
      return
    }

    let formatOption = (option) => {
      if (!option.id) {
        return option.text
      }

      let div = $(`<div class='d-flex justify-content-between'></div>`)
      div.append($('<div>').text(option.text))
      div.append($(`<div class='badge badge-primary'>`).text(option.element.dataset.badge))
      return div
    }

    setTimeout(() => {
      this.stateSelectTargets.forEach((s) => {
        $(s).select2({
          templateResult: formatOption
        })
        $(s).on('change', (ev) => {
          window.location = $(ev.target).find('option:selected').data('url');
        })
      })
    }, 0)
  }

  selectState(ev) {
    window.location = $(ev.target).find('option:selected').data('url');
  }
}
